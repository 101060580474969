import RenderComponents from "@utilities/renderComponents";
import slugify from "@utilities/slugify";
import clsx from "clsx";
import IconExternal from "../../public/assets2019/img/svg/external.svg";

import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import Cta from "@components/Cta/Cta";
import { fetchApi } from "@utilities/fetchApi";
import Link from "@components/Link/Link";
import { useFacultyDetails } from "../../context/facultyDetails";
import styles from "./C14.1_FacultyProfilePublications.module.scss";

export interface TabItem {
  fields: {
    content: any;
  };
}

interface PublicationAuthorFullName {
  suppressPublication: boolean;
  publicationAuthorFullName: {
    facultyProfileData: {
      profileSurname: string;
      profileFirstname: string;
    };
  };
}

interface PublicationAuthorsCollection {
  items: PublicationAuthorFullName[];
}

interface Publication {
  basePageTemplate?: {
    pageTitle: string;
  };
  publicationNote?: string;
  publicationTitle?: string;
  publicationType?: string;
  slug?: string;
  publicationYear?: number;
  publicationAuthorsCollection?: PublicationAuthorsCollection;
  publicationExternalAuthors?: string;
}

export interface FacultyProfilePublicationsProps {
  awardsAndHonours: any[];
  researchInterests: any[];
}

interface CustomTabs {
  customFirstTabAnchorToken?: string;
  customFirstTabText?: string;
  customTab1AnchorToken?: string | null;
  customTab1Text?: string | null;
  customTab2AnchorToken?: string | null;
  customTab2Text?: string | null;
  publicationTabs1AnchorToken?: string;
  publicationTabs1Text?: string;
  publicationTabs1TypeList?: string;
  publicationTabs2AnchorToken?: string;
  publicationTabs2Text?: string;
  publicationTabs2TypeList?: string;
  publicationTabs3AnchorToken?: string;
  publicationTabs3Text?: string;
  publicationTabs3TypeList?: string;
}

export const FacultyProfilePublications = (props: {
  content: FacultyProfilePublicationsProps;
}) => {
  const facultyDetails = useFacultyDetails();
  const profileFirstname =
    facultyDetails.facultyProfileData?.fields?.profileFirstname;
  const profileSurname =
    facultyDetails.facultyProfileData?.fields?.profileSurname;

  const { content } = props;

  /* QA test condition - Once passed QA this consition can be removed */
  if (process.env.NEXT_PUBLIC_URL !== "https://www.london.edu") {
    console.log("C14.1_FacultyProfilePublications component: ", content);
  }
  /* end QA test condition */

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [toggleIndexes, setToggleIndexes] = useState<number[]>([]);
  const [tabContent, setTabContent] = useState<any>();
  const [didMount, setDidMount] = useState(false);
  const tabsRef = useRef<HTMLElement>(null);
  const [facultyProfilePublicationsData, setFacultyProfilePublicationsData] =
    useState<CustomTabs>({});

  const [
    facultyProfilePublicationsContent,
    setFacultyProfilePublicationsContent,
  ] = useState<Publication[]>([]);
  const { asPath, replace } = useRouter();

  const { awardsAndHonours, researchInterests } = content;

  function filterPublications(publications, type) {
    // Filter based on publication type and suppressPublication authors
    const filteredPublications = publications.filter((publication) => {
      if (publication.publicationType === null) {
        publication.publicationType = "P";
      }
      if (publication && publication.publicationAuthorsCollection) {
        publication.publicationAuthorsCollection.items =
          publication?.publicationAuthorsCollection?.items.filter(
            (author) => !author.suppressPublication
          );
      }

      return publication.publicationType === type;
    });

    return sortPublications(filteredPublications);
  }
  // Sort by year descending and title ascending
  function sortPublications(publications) {
    publications.sort((a, b) => {
      if (a.publicationYear === b.publicationYear) {
        return a.publicationTitle
          ? a.publicationTitle.localeCompare(b.publicationTitle)
          : b.publicationTitle - a.publicationTitle;
      }
      return b.publicationYear - a.publicationYear;
    });
    return publications;
  }

  useEffect(() => {
    if (tabContent && tabContent[0]?.tabAnchorToken) {
      if (!didMount) {
        setDidMount(true);
        let id = /(#)([a-z]+[\w\-\.]*)$/gi.exec(asPath);

        if (!id) return;

        const tabIndex = tabContent.findIndex(
          (tab) => slugify(tab.tabAnchorToken) === id![2]
        );

        if (tabIndex === -1) return;

        setTabIndex(tabIndex);

        if (tabsRef) tabsRef.current?.scrollIntoView();
        return;
      }

      replace({
        hash: slugify(tabContent[tabIndex].tabAnchorToken),
      });
    }
  }, [tabContent, tabIndex]);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchApi("/api/getFacultyProfilePublicationData", {
        profileFirstname: profileFirstname,
        profileSurname: profileSurname,
      });
      const { tabsData, contentData } = data;
      setFacultyProfilePublicationsData(tabsData);
      contentData?.items[0] &&
        setFacultyProfilePublicationsContent(
          contentData?.items[0]?.linkedFrom?.facultyProfileDetailCollection
            ?.items[0]?.linkedFrom?.publicationAuthorCollection?.items[0]
            ?.linkedFrom?.publicationDetailCollection?.items
        );
    };
    getData();
  }, []);

  useEffect(() => {
    const tabContentStructure = [
      ...(researchInterests
        ? [
            {
              tabText: facultyProfilePublicationsData?.customFirstTabText,
              tabAnchorToken:
                facultyProfilePublicationsData?.customFirstTabAnchorToken,
              tabContent: researchInterests,
            },
          ]
        : []),
      ...(facultyProfilePublicationsContent?.length > 0 &&
      filterPublications(facultyProfilePublicationsContent, "P")?.length > 0
        ? [
            {
              tabText: facultyProfilePublicationsData?.publicationTabs1Text,
              tabAnchorToken:
                facultyProfilePublicationsData?.publicationTabs1AnchorToken,
              tabContent: filterPublications(
                facultyProfilePublicationsContent,
                "P"
              ),
            },
          ]
        : []),
      ...(facultyProfilePublicationsContent?.length > 0 &&
      filterPublications(facultyProfilePublicationsContent, "W")?.length > 0
        ? [
            {
              tabText: facultyProfilePublicationsData?.publicationTabs2Text,
              tabAnchorToken:
                facultyProfilePublicationsData?.publicationTabs2AnchorToken,
              tabContent: filterPublications(
                facultyProfilePublicationsContent,
                "W"
              ),
            },
          ]
        : []),
      ...(facultyProfilePublicationsContent?.length > 0 &&
      (filterPublications(facultyProfilePublicationsContent, "C")?.length > 0 ||
        filterPublications(facultyProfilePublicationsContent, "B")?.length > 0)
        ? [
            {
              tabText: facultyProfilePublicationsData?.publicationTabs3Text,
              tabAnchorToken:
                facultyProfilePublicationsData?.publicationTabs3AnchorToken,
              tabContent: sortPublications(
                filterPublications(
                  facultyProfilePublicationsContent,
                  "C"
                )?.concat(
                  filterPublications(facultyProfilePublicationsContent, "B")
                )
              ),
            },
          ]
        : []),
      ...(awardsAndHonours
        ? [
            {
              tabText: facultyProfilePublicationsData?.customTab1Text,
              tabAnchorToken:
                facultyProfilePublicationsData?.customTab1AnchorToken,
              tabContent: awardsAndHonours,
            },
          ]
        : []),
    ];

    setTabContent(tabContentStructure);
  }, [facultyProfilePublicationsData, facultyProfilePublicationsContent]);

  const handleOnToggleClick = () => {
    setToggleIndexes([...toggleIndexes, tabIndex]);
  };

  const displayReadMore = (item) => {
    return item.tabContent.some(
      (content) =>
        content.slug &&
        item.tabContent.length > 2 &&
        !toggleIndexes.includes(tabIndex)
    );
  };

  return (
    <div
      className={clsx(
        "component",
        styles["programme-benefits"],
        styles["publication-listing"],
        styles.blue,
        tabContent?.length <= 0 && styles.noContent
      )}
    >
      <div className="wrapper">
        {tabContent?.length > 0 && (
          <section ref={tabsRef} className={clsx(styles["tabs"], "cf")}>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList>
                {tabContent &&
                  tabContent?.map(({ tabText }, index) => (
                    <Tab key={`${tabText}${index}`}>{tabText} </Tab>
                  ))}
              </TabList>
              {tabContent &&
                tabContent.map((item, index) => (
                  <TabPanel key={`tab-content${index}`}>
                    <div
                      className={clsx(
                        "component",
                        styles["search-results"],
                        styles["publication-listing"],
                        displayReadMore(item) && styles["limited-height"],
                        toggleIndexes.includes(tabIndex) &&
                          styles["display-all"]
                      )}
                    >
                      {item.tabContent &&
                        item.tabContent.map((content, index) => {
                          if (content.slug) {
                            return (
                              <div
                                key={`${content.publicationTitle}-${index}`}
                                className={clsx(
                                  "component",
                                  styles.results,
                                  styles.row
                                )}
                              >
                                {content.publicationYear && (
                                  <p className={styles["result-copy-a"]}>
                                    {content.publicationYear}
                                  </p>
                                )}
                                <h4 className={styles["result-heading"]}>
                                  {content?.publicationNotes &&
                                  content?.publicationNotes.startsWith(
                                    "url="
                                  ) ? (
                                    <a
                                      target={"_blank"}
                                      href={
                                        content.publicationNotes.match(
                                          /\[(.*?)\]/
                                        )[1]
                                      }
                                      rel="noreferrer"
                                    >
                                      <i className={styles["icon-external"]}>
                                        <IconExternal />
                                      </i>
                                      {content.basePageTemplate?.pageTitle ||
                                        content.publicationTitle}
                                    </a>
                                  ) : (
                                    <Link href={content.slug}>
                                      <a>
                                        {content.basePageTemplate?.pageTitle ||
                                          content.publicationTitle}
                                      </a>
                                    </Link>
                                  )}
                                </h4>
                                <p className={styles["result-copy-a"]}>
                                  {content.publicationExternalAuthors}
                                </p>
                                {content.publicationPublishingDetails && (
                                  <p className={styles["result-copy-a"]}>
                                    {content.publicationPublishingDetails}
                                  </p>
                                )}
                              </div>
                            );
                          } else {
                            return RenderComponents([content]);
                          }
                        })}
                    </div>
                    {displayReadMore(item) && (
                      <>
                        <Cta
                          onClick={handleOnToggleClick}
                          url=""
                          customClass="see-more-toggle"
                          light
                          linkText="See More"
                          icon="icon-chevron"
                          type="tertiary"
                        />
                      </>
                    )}
                  </TabPanel>
                ))}
            </Tabs>
          </section>
        )}
      </div>
    </div>
  );
};

export default FacultyProfilePublications;
